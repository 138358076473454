import "./scss/styles.scss";
import { gsap } from "gsap";


const image = 'https://media.complex.com/websites/seeyounextyear/bg-1920.jpg';
const wrapper = document.getElementById('home-wrapper');
const planeEl = document.getElementById('plane');
const timeline = gsap.timeline({
    paused: true,
    // onComplete: () => {
    //     planeEl.classList.add('static');
    // }
});
const plane = "https://media.complex.com/websites/seeyounextyear/plane-sprites-ps-export.png"

loadAnimation();

// load bg image, then animate on callback:
load(image)
    .then(() => {
        // now that bg image is loaded, load the spritesheet before running the animation
        load(plane)
        .then(() => {
            animate();
        })
        .catch(safetyFade(wrapper))
    })
    .catch(() => {
        // fade in page anyways if bg image doesn't load
        safetyFade(wrapper);
    });


function load(src) {
    return new Promise(function(resolve, reject) {
        const image = new Image();
        image.addEventListener('load', resolve);
        image.addEventListener('error', reject);
        image.src = src;
    });
}

// In case something goes wrong, fade in anyways
function safetyFade(element) {
    timeline.to(element, {opacity: 1, duration: 1.5, ease: "sine", }, 0)
    timeline.play();
}


function loadAnimation() {
    // calculate x coordinate of plane offscreen:
    const width = wrapper.offsetWidth * 2;
    // Fly in from above wrapper height:
    const height = wrapper.offsetHeight * 0.1 * -1;

    timeline
    .set("#plane-outer-container", {transformOrigin: `center left`})
    .set("#plane", {backgroundPosition: 'center 100%'})
    .to(wrapper, {opacity: 1, duration: 1.5, ease: "sine", }, 0.2)
    .from("#plane-outer-container", {duration: 4, x: width, y: height, transform: "scale(0.01)", rotation: -15, ease: "power4", delay: 1.2}, 0.2)
    .to("#plane", {duration: 1.2, backgroundPosition: "center 0%", ease: "steps(14)", repeat: 2}, 0.4)
    .fromTo("#cta", {opacity: 0, scale: 0.1}, {opacity: 1, scale: 1, duration: 0.4}, ">-0.3")
    .add(() => planeEl.classList.add('static'), 3.5)
    .from("#title", {duration: 2, opacity: 0}, 0.4)
}

function animate() {
    timeline.play();
}